<template>
  <Container>
    <Header>
      Styles
      <template #right>
        <BadgeBtn @click="openStyle()">
          + Style
        </BadgeBtn>
      </template>
    </Header>
    <StyleTable />
  </Container>
</template>

<script>
import StyleTable from "../components/tabels/StyleTable.vue"
import comp from "../components/base"
import { mapActions } from "vuex"

export default {
  name: "Styles",
  components: {
    ...comp,
    StyleTable,
  },
  methods: {
    ...mapActions(["openStyle"]),
  },
}
</script>
