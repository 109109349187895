<template>
  <Table>
    <template v-slot:headRow>
      <TH>Edit</TH>
      <TH>Name</TH>
      <TH>Color</TH>
    </template>
    <template v-slot:body>
      <TR v-for="(item, index) in styles" :key="index">
        <TD>
          <ButtonGroup>
            <Button @click="openStyle(item.id)">
              <BIconPencilSquare />
            </Button>
            <Button @click="openDeleteStyle(item.id)">
              <BIconTrash />
            </Button>
          </ButtonGroup>
        </TD>
        <TD>{{ item.name }}</TD>
        <TD>
          <span class="badge" :style="{ backgroundColor: item.color }">
            {{ item.color }}
          </span>
        </TD>
      </TR>
    </template>
  </Table>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import comp from "@/components/base"
import icons from "../../assets/icons"

export default {
  name: "StyleTable",
  components: {
    ...comp,
    ...icons,
  },
  methods: {
    ...mapActions(["openDeleteStyle", "openStyle"]),
  },
  computed: {
    ...mapGetters(["styles"]),
  },
}
</script>
